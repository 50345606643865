.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 0;
  min-height: var(--footer-height);

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-app-logo {
      width: 40px;
      margin-right: 10px;
    }
  }
}
