.loading {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0;

  .loading-app {
    width: 15px;
    padding: 2px;

    &.logo {
      animation: logo-spin infinite 1s linear;
    }
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
