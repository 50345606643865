// Containers
@import '../containers/App/styles';
@import '../containers/Home/styles';
@import '../containers/Services/styles';
@import '../containers/Contacts/styles';
@import '../containers/Team/styles';
@import '../containers/Gallery/styles';
// Components
@import '../components/Header/styles';
@import '../components/Footer/styles';
@import '../components/Separator/styles';
@import '../components/Loading/styles';
@import '../components/Contact/styles';
@import '../components/MemberCard/styles';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  &.nav {
    color: var(--light-color);
    text-transform: none;
    font-size: var(--header-font-size);
  }
}

h1 {
  &.title {
    font-size: 3em;
  }
}

h2 {
  &.headline {
    font-size: 3em;
    margin-bottom: 1em;
  }

  &.title {
    font-size: 2em;
    color: var(--secondary-color);
  }
}

p {
  &.headline {
    font-weight: normal;
    font-size: var(--headline-font-size);
  }

  &.nav {
    font-size: var(--nav-font-size);
  }
}

.container {
  margin-top: calc(var(--header-height));

  &.inner {
    margin: calc(var(--header-height) + 2em) 0 4em 0;
  }
}

.link {
  cursor: pointer;
  color: var(--primary-color);
}

:root {
  // Colors
  --primary-color: #1c92d2;
  --secondary-color: #8ec835;
  --light-color: #fafafa;
  --white-color: white;
  --dark-color: #282c34;

  // Widths
  --container-width: 950px;
  --header-height: 22vh;
  --header-font-size: calc(5px + 2vmin);
  --footer-height: 10vh;
  --nav-font-size: calc(5px + 2vmin);
  --headline-font-size: calc(9px + 2vmin);
  --space-border: 1em;
  --space-bottom-separator: 30px;
}
